// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '../public/assets/css/plugins.css';
@import '../public/assets/scss/style';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

.portfolio-style--3 .content {
  z-index: 3 !important;
  .portfoliotype {
    z-index: 10 !important;
  }
  .title a {
    z-index: 10 !important;
    color: #069;
  }
}

.white {
  color: #aaa;
}

.red {
  color: rgb(155, 33, 86);
}

.gravitymedia_bg {
  background: url('../public/assets/images/bg/bg-image-1.jpg');
}

.modal-video {
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  .modal-video-movie-wrap,
  iframe {
    z-index: 9999 !important;
  }
}

.noscroll {
  position: fixed;
  height: 100%;
  width: 100%;
}

.active-dark .bg-gray {
  background-color: #fff !important;
}

.active-dark .bg-gray ul.brand-style-2 img {
  filter: none !important;
}
